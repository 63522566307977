import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { CommonModule, CurrencyPipe, DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TokenInterceptorService } from './helpers/auth/token-interceptor.service';

import { SearchableSelectComponent } from './components/searchable-select/searchable-select.component';
import { ShepherdSearchableSelectComponent } from './components/shepherd-searchable-select/shepherd-searchable-select.component';
import { environment } from 'src/environments/environment';
import { PageNotFoundComponent } from './page-not-found.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { AvatarModule, AvatarSource } from 'ngx-avatars';
import { UpdateNotifierComponent } from './components/update-notifier/update-notifier.component';
import { GroupLeaderSelectComponent } from './components/group-leader-select/group-leader-select.component';
import { TeamLeaderSelectComponent } from './components/team-leader-select/team-leader-select.component';
import { TranslateLoader, TranslateModule, TranslateParser, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LaravelLikeParserService } from './services/laravel-like-parser.service';
import { APIENDPOINT } from './helpers/constant/api-end-points';
import { LocaleProvider } from './locale.provider';

import en from '@angular/common/locales/en';
import { provideNgxMask } from 'ngx-mask';
import { IsUrlLikePipe } from './helpers/pipes/is-url-like.pipe';
import { IonRangeCalendarModule } from '@googlproxer/ion-range-calendar';
import { RosterSearchComponent } from './components/roster-search/roster-search.component';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { NgxTinymceModule } from 'ngx-tinymce';

registerLocaleData(en, 'en');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.baseUrl + APIENDPOINT.LANGUAGE.LOAD, '');
}

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, NotAuthorizedComponent, UpdateNotifierComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
      toastDuration: 4000,
      swipeBackEnabled: false,
      backButtonText: '',
      innerHTMLTemplatesEnabled: true
    }),

    AppRoutingModule,
    SearchableSelectComponent, 
    ShepherdSearchableSelectComponent,
    GroupLeaderSelectComponent,
    TeamLeaderSelectComponent,
    RosterSearchComponent,
    // GroupTeamListComponent,
    CommonModule,
    FormsModule,
    HttpClientModule,
    
    // Translate Module
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      parser: {
        provide: TranslateParser,
        useClass: LaravelLikeParserService, // Use the custom parser
      }
    }),
    // Avatars
    AvatarModule.forRoot({
      sourcePriorityOrder: [AvatarSource.CUSTOM, AvatarSource.VALUE, AvatarSource.INITIALS]
    }),
    // storage
    IonicStorageModule.forRoot({
      name: '__avance',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    // ionRangeCalendar
    IonRangeCalendarModule,
    InViewportModule,
    // TinyMCE Editor
    NgxTinymceModule.forRoot({
      baseURL: './assets/tinymce/'
    })
  ],
  providers: [
    LocaleProvider,
    provideNgxMask(),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    TranslatePipe,
    DatePipe,
    CurrencyPipe,
    IsUrlLikePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
