import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollCustomEvent, IonicModule, RefresherCustomEvent } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from 'ngx-avatars';
import { NgPipesModule } from 'ngx-pipes';
import { FetchDataService } from 'src/app/services/fetch-data.service';
import { ShepherdService } from 'src/app/services/shepherd.service';

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, AvatarModule, TranslateModule, NgPipesModule],
  selector: 'app-roster-search',
  templateUrl: './roster-search.component.html',
  styleUrls: ['./roster-search.component.scss'],
})
export class RosterSearchComponent implements OnInit, OnChanges {
  @Input() title = 'Search';
  @Input() multiple = false;
  @Input() selected: any[] = [];
  @Output() selectedChanged: EventEmitter<any> = new EventEmitter();

  church: number = Number(localStorage.getItem('userChurchId'));

  isOpen=false;
  isSearching = false;
  searching = false;
  fetching: boolean = false;
  filtered: any[] = [];
  filterData: string|undefined;

  constructor(
    public shepherdService: ShepherdService
  ) { }

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    this.church = Number(localStorage.getItem('userChurchId'));
  }


  loadData(refreshOrInfiniteEvent: any = null, isInfinite: boolean = false) {
    if (this.isSearching == true && this.filterData) {
      this.searching = true;
      this.shepherdService.searchRoster(this.church, isInfinite ? this.filtered.length : 0, this.filterData).subscribe((res: any) => {
        this.searching = false;
        if (res.success) {
          if(isInfinite) {
            this.filtered.push(...res.rosters);
          } else {
            this.filtered = res.rosters;
          }
        }
        if (refreshOrInfiniteEvent) {
          (refreshOrInfiniteEvent as RefresherCustomEvent|InfiniteScrollCustomEvent).target.complete();
        }
      })
      
    } else {
      if (!refreshOrInfiniteEvent) {
        this.fetching = true;
      }
      this.shepherdService.searchRoster(this.church, isInfinite ? this.filtered.length : 0).subscribe((res:any) => {
        if(res.success) {
          if(isInfinite) {
            this.filtered.push(...res.rosters);
          } else {
            this.filtered = res.rosters;
          }
        }
        if (refreshOrInfiniteEvent) {
          (refreshOrInfiniteEvent as RefresherCustomEvent|InfiniteScrollCustomEvent).target.complete();
        }
        this.fetching = false;
      })
    }
  }
  
  open() {
    this.isOpen = true;
    this.loadData();
  }

  cancel(){
    this.isOpen = false;
  }

  select() {
    let selected: any[] = [];
    if(this.isSearching == true && this.filtered.length) {
      selected = this.filtered.filter((item)=>item.selected)
    } else {
      selected = this.filtered.filter((item) => item.selected);
    }
    this.selected = selected;
    this.selectedChanged.emit(selected);
    this.isOpen = false;
  }

  itemSelected(item:any) {
    if(!this.multiple) {
      this.selected = [item];
      this.selectedChanged.emit(this.selected);
      this.isOpen = false;
    }
  }

  filter(event:any) {
    this.isSearching = true;
    this.filterData = event.detail.value?.toLowerCase();
    if(this.filterData) {
      this.isSearching = true;
      if(this.filterData.length >= 3) {
        this.searching = true;
        this.shepherdService.searchRoster(this.church, 0, this.filterData).subscribe((res:any) => {
          this.isSearching = false;
          if(res.success) {
            this.filtered = res.rosters;
          } else {
            this.filtered = [];
          }
        });
      }
    } else {
      this.isSearching = false;
      this.shepherdService.searchRoster(this.church, 0).subscribe((res:any) => {
        this.searching = false;
        if(res.success) {
          this.filtered = res.rosters;
        }
      });
    }
  }

}
