<div *ngIf="selected.length; else placeholder">
  <div *ngFor="let item of selected; let i = index; let last = last;">
    <ng-container *ngIf="item.name">
      {{item.name}}
    </ng-container>
  </div>
</div>

<ng-template #placeholder>
  <div class="flex pl-10 ion-justify-content-between">
    <span class="ion-label">{{'events.search_roster' | translate | ucfirst}}</span>
    <ion-icon color="medium" class="font-s-sm" name="caret-down-sharp" ></ion-icon>
  </div>
</ng-template>

<ion-modal [isOpen]="isOpen">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button color="dark" (click)="cancel()" translate>cancel</ion-button>
        </ion-buttons>
        <ion-title>{{title}}</ion-title>
        <ion-buttons slot="end" *ngIf="multiple==true">
          <ion-button (click)="select()" translate>select</ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar (ionChange)="filter($event)"></ion-searchbar>
      </ion-toolbar>
    </ion-header>
    <ion-content >
      <ion-refresher slot="fixed" (ionRefresh)="loadData($event)" pullFactor="0.8" pullMin="60" pullMax="120">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div *ngIf="isSearching==true; else notSearching">
        <ion-item *ngFor="let item of filtered"  (click)="itemSelected(item)">
          <ion-avatar>
            <ngx-avatars initialsSize="3" [name]="item.name" [src]="item.photo" size="38"></ngx-avatars>
          </ion-avatar>
          <ion-checkbox slot="start" [(ngModel)]="item.selected"></ion-checkbox>
          <ion-label class="pl-20">{{item.name}}</ion-label>
        </ion-item>
        <div  *ngIf="filtered.length == 0 && isSearching">
          <ion-card class="center" *ngIf="searching == true">
            <ion-spinner ></ion-spinner>
          </ion-card>
          <ion-card class="center" *ngIf="searching == false" translate>no_items_match</ion-card>
        </div>
      </div>

      <ng-template #notSearching>
        <ng-container *ngIf="fetching; else notFetching">
          <div class="ion-text-center ion-margin">
            <ion-spinner ></ion-spinner>
          </div>
        </ng-container>
        <ng-template #notFetching>
          <ion-item *ngFor="let item of filtered"  (click)="itemSelected(item)">
            <ion-avatar>
              <ngx-avatars initialsSize="3" [name]="item.name" [src]="item.photo" size="38"></ngx-avatars>
            </ion-avatar>
            <ion-checkbox slot="start" [(ngModel)]="item.selected"></ion-checkbox>
            <ion-label class="pl-20">{{item.name}}</ion-label>
          </ion-item>
          <div  *ngIf="filtered.length == 0">
            <ion-card class="center" translate>empty_list</ion-card>
          </div>
        </ng-template>
      </ng-template>

      <ion-infinite-scroll threshold="25%" position="bottom" (ionInfinite)="loadData($event, true)">
        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="{{'loading_more'|translate}}...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ng-template>
  
</ion-modal>
