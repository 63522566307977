import { Pipe, PipeTransform } from '@angular/core';
import { normalizeUrl } from '../functions';

@Pipe({
  name: 'isUrlLike',
  standalone: true
})
export class IsUrlLikePipe implements PipeTransform {

  transform(value: string | null, defaultValue: string|boolean = false): boolean|string {
    if (value?.trim().length) {
      let url = normalizeUrl(value.trim());
      return url.trim() != '/zone/sheep' && url.length >= 5 ? url : false;
    }
    return defaultValue;
  }

}
