<ion-content class="ion-padding ion-text-center ion-justify-content-center">
  <div class="d-flex flex-div ion-padding ion-margin-top">
    <img src="/assets/img/access_denied.svg" alt="">
  </div>
  <h2 translate>unauthorized_access</h2>
  <p translate>tried_to_access</p>
  <br>
  <ion-button fill="outline" expand="block" [routerLink]="backUrl" replaceUrl="true">
    <ion-icon slot="start" name="arrow-undo-outline"></ion-icon>
    {{'bringme_home' | translate}}
  </ion-button>
</ion-content>
