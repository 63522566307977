import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {

  constructor(private navCtrl: NavController) { }

  public zones = [
    {title: 'Sheep', url:'/sheep/news', img: '../../../../../assets/logos/sideBar/Sheep.png'},
    {title: 'Shepherd', url:'/shepherd/sheep', img: '../../../../../assets/logos/sideBar/Shepherd zone white.png'},
    {title: 'Account Holder', url:'/account-holder/profile', img: '../../../../../assets/logos/sideBar/Account Holder White.png'},
  ];

  ngOnInit() {}

  goBack() {
    this.navCtrl.back()
  }

}
