import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {

  get currentLocale(): string {
    return this.translate.currentLang || 'en';
  }

  constructor(
    private translate: TranslateService
  ) { }

}