import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { BehaviorSubject } from 'rxjs';
import { MarketingAd } from '../helpers/constant/marketing-news';

@Injectable({
  providedIn: 'root'
})

export class WebSocketService {
  echo: Echo;

  // Messages receipts
  private _supportMessageRead = new BehaviorSubject<{
    message_id: number,
    sender_id: number,
    message: any
  }|null>(null);
  private _supportMessageDelivered = new BehaviorSubject<{
    message_id: number,
    sender_id: number,
    message: any
  }|null>(null);
  private _coachingMessageRead = new BehaviorSubject<{
    course_id: number,
    message_id: number,
    sender_id: number,
    recipient_id: number,
    message: any
  }|null>(null);
  private _coachingMessageDelivered = new BehaviorSubject<{
    course_id: number,
    message_id: number,
    sender_id: number,
    recipient_id: number,
    message: any
  }|null>(null);

  private _groupMessageRead = new BehaviorSubject<{
    group_id: number,
    message_id: number,
    sender_id: number,
    receipt: any,
    status: {
      read_list: any[],
      all_read: boolean,
      all_received: boolean
    }
  }|null>(null);
  private _groupMessageDelivered = new BehaviorSubject<{
    group_id: number,
    message_id: number,
    sender_id: number,
    receipt: {
      message_id: number,
      user_id: number
      read_at?: string,
      delivered_at?: string,
      status?: string
    },
    status: {
      read_list: any[],
      all_read: boolean,
      all_received: boolean
    }
  }|null>(null);

  private _teamMessageRead = new BehaviorSubject<{
    team_id: number,
    message_id: number,
    sender_id: number,
    receipt: {
      message_id: number,
      user_id: number
      read_at?: string,
      delivered_at?: string,
      status?: string
    },
    status: {
      read_list: any[],
      all_read: boolean,
      all_received: boolean
    }
  }|null>(null);
  private _teamMessageDelivered = new BehaviorSubject<{
    team_id: number,
    message_id: number,
    sender_id: number,
    receipt: any,
    status: {
      read_list: any[],
      all_read: boolean,
      all_received: boolean
    }
  }|null>(null);

  private _incomingSupportMessage = new BehaviorSubject<any>([]);
  private _outgoingSupportMessage = new BehaviorSubject<any>([]);
  
  private _incomingGroupMessage = new BehaviorSubject<any>([]);
  private _outgoingGroupMessage = new BehaviorSubject<any>([]);

  private _incomingTeamMessage = new BehaviorSubject<any>([]);
  private _outgoingTeamMessage = new BehaviorSubject<any>([]);

  private _incomingCoachingMessage = new BehaviorSubject<any>([]);
  private _outgoingCoachingMessage = new BehaviorSubject<any>([]);

  private _supportQueryItem = new BehaviorSubject<any>([]);

  private _updateConnectBadge = new BehaviorSubject<{type: string, id: number}>({type: 'init', id: 0});
  private _updateCoachingBadge = new BehaviorSubject<{type: string, id: number}>({type: 'init', id: 0});

  private _incomingMarketingNews = new BehaviorSubject<{news: MarketingAd, type: string}|null>(null);

  constructor() {
    this.establishWebSocketConnection();
  }

  establishWebSocketConnection() {
    const pusherKey = 'local';
    const webSocketHost = environment.webSocketHost;
    var pusherData: any = {
      cluster: 'mt1',
      wsHost: webSocketHost,
      disableStats: false,
      forceTLS: true,
      encrypted: true,
      scheme: 'https',
      enabledTransports: ["ws", "wss"]
    };
    if (webSocketHost == '127.0.0.1') {
      pusherData.wsPort = 6001;
      pusherData.wssPort = 6001;
      pusherData.forceTLS = false;
      pusherData.encrypted = false;
      pusherData.scheme = 'http';
    }
    const pusher = new Pusher(pusherKey, pusherData);

    this.echo = new Echo({
      broadcaster: 'pusher',
      client: pusher,
      forceTLS: true,
    });
  }

  // Coaching messages realtime status
  getCoachingDeliveredMessageNotifications() {
    return this._coachingMessageDelivered.asObservable();
  }

  listenToCoachingDeliveredMessageNotifications(courseId: number) {
    let senderId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('course-message-receipt-delivered.'+ courseId +'.' + senderId).listen('CoachingMessageReceiptEvent', (e: any) => {
      this._coachingMessageDelivered.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  getCoachingReadMessageNotifications() {
    return this._coachingMessageRead.asObservable();
  }

  listenToCoachingReadMessageNotifications(courseId: number) {
    let senderId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('course-message-receipt-read.'+ courseId +'.' + senderId).listen('CoachingMessageReceiptEvent', (e: any) => {
      this._coachingMessageRead.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  // Group messages realtime status
  getGroupDeliveredMessageNotifications() {
    return this._groupMessageDelivered.asObservable();
  }

  listenToGroupDeliveredMessageNotifications(groupId: number) {
    let senderId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('group-message-receipt-delivered.'+ groupId +'.' + senderId).listen('GroupMessageReceiptEvent', (e: any) => {
      this._groupMessageDelivered.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  getGroupReadMessageNotifications() {
    return this._groupMessageRead.asObservable();
  }

  listenToGroupReadMessageNotifications(groupId: number) {
    let senderId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('group-message-receipt-read.'+ groupId +'.' + senderId).listen('GroupMessageReceiptEvent', (e: any) => {
      this._groupMessageRead.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  // Team messages realtime status
  getTeamDeliveredMessageNotifications() {
    return this._teamMessageDelivered.asObservable();
  }

  listenToTeamDeliveredMessageNotifications(teamId: number) {
    let senderId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('team-message-receipt-delivered.'+ teamId +'.' + senderId).listen('TeamMessageReceiptEvent', (e: any) => {
      this._teamMessageDelivered.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  getTeamReadMessageNotifications() {
    return this._teamMessageRead.asObservable();
  }

  listenToTeamReadMessageNotifications(teamId: number) {
    let senderId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('team-message-receipt-read.'+ teamId +'.' + senderId).listen('TeamMessageReceiptEvent', (e: any) => {
      this._teamMessageRead.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  // Support message realtime status
  getDeliveredMessageNotifications() {
    return this._supportMessageDelivered.asObservable();
  }

  listenToDeliveredMessageNotifications(supportId: number) {
    let senderId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('support-message-receipt-delivered.'+ supportId +'.' + senderId).listen('MessageReceiptEvent', (e: any) => {
      this._supportMessageDelivered.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  getReadMessageNotifications() {
    return this._supportMessageRead.asObservable();
  }

  listenToReadMessageNotifications(supportId: number) {
    let senderId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('support-message-receipt-read.'+ supportId +'.' + senderId).listen('MessageReceiptEvent', (e: any) => {
      this._supportMessageRead.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  activeGroupTeamBadge(type: 'group'|'team', id: number) {
    return this._updateConnectBadge.next({type, id});
  }

  updateConnectBadge() {
    return this._updateConnectBadge.asObservable();
  }

  updateCoachingBadge() {
    return this._updateCoachingBadge.asObservable();
  }

  getNewMarketingNews() {
    return this._incomingMarketingNews.asObservable();
  }

  listenToIncomingMarketingNews() {
    let receiverId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('marketing-news.'+receiverId).listen('NewMarketingAdEvent', (e: any) => {
      this._incomingMarketingNews.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  // Support listeners
  listenToIncomingSupportMessage() {
    let receiverId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('chat-room-incoming-message-support-'+receiverId).listen('MessageReceived', (e: any) => {
      this._incomingSupportMessage.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  newSupportIncomingMessage() {
    return this._incomingSupportMessage.asObservable();
  }

  listenToRealtimeSupportQueries() { // Listen if a new support query is open
    let receiverId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('realtime-support-queries-update-'+ receiverId).listen('SupportQueryUpdate', (e: any) => {
      this._supportQueryItem.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  newSupportItem() {
    return this._supportQueryItem.asObservable();
  }

  listenToOutgoingSupportMessage(chatRoomId: number) {
    let senderId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('chat-room-outgoing-message-support-'+ chatRoomId +'-'+senderId).listen('MessageSent', (e: any) => {
      this._outgoingSupportMessage.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  newSupportOutgoingMessage() {
    return this._outgoingSupportMessage.asObservable();
  }

  // Group listeners
  listenToIncomingGroupMessage() {
    let receiverId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('chat-room-incoming-message-group-'+ receiverId).listen('MessageReceived', (e: any) => {
      this._incomingGroupMessage.next(e);
      this.activeGroupTeamBadge('group', e.chatRoomId);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  newGroupIncomingMessage() {
    return this._incomingGroupMessage.asObservable();
  }

  listenToOutgoingGroupMessage(chatRoomId: number) {
    let senderId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('chat-room-outgoing-message-group-'+ chatRoomId +'-'+senderId).listen('MessageSent', (e: any) => {
      this._outgoingGroupMessage.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  newGroupOutgoingMessage() {
    return this._outgoingGroupMessage.asObservable();
  }

  // Team listeners
  listenToIncomingTeamMessage() {
    let receiverId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('chat-room-incoming-message-team-'+ receiverId).listen('MessageReceived', (e: any) => {
      this._incomingTeamMessage.next(e);
      this.activeGroupTeamBadge('team', e.chatRoomId);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  newTeamIncomingMessage() {
    return this._incomingTeamMessage.asObservable();
  }

  listenToOutgoingTeamMessage(chatRoomId: number) {
    let senderId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('chat-room-outgoing-message-team-'+ chatRoomId +'-'+senderId).listen('MessageSent', (e: any) => {
      this._outgoingTeamMessage.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  newTeamOutgoingMessage() {
    return this._outgoingTeamMessage.asObservable();
  }

  // Coaching listeners
  listenToIncomingCoachingMessage() {
    let receiverId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('chat-room-incoming-message-coaching-'+ receiverId).listen('MessageReceived', (e: any) => {
      this._incomingCoachingMessage.next(e);
      this._updateCoachingBadge.next({type: 'coaching', id: e.chatRoomId});
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  newCoachingIncomingMessage() {
    return this._incomingCoachingMessage.asObservable();
  }

  listenToOutgoingCoachingMessage(chatRoomId: number) {
    let senderId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('chat-room-outgoing-message-coaching-'+ chatRoomId +'-'+senderId).listen('MessageSent', (e: any) => {
      this._outgoingCoachingMessage.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  newCoachingOutgoingMessage() {
    return this._outgoingCoachingMessage.asObservable();
  }

  registerWebSocketListeners() {
    this.establishWebSocketConnection();
    
    this.listenToIncomingMarketingNews();
    this.listenToIncomingCoachingMessage();
    this.listenToIncomingGroupMessage();
    this.listenToRealtimeSupportQueries();
    this.listenToIncomingSupportMessage();
    this.listenToIncomingTeamMessage();
  }
}