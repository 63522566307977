import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollCustomEvent, IonicModule, RefresherCustomEvent } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule, AvatarSource } from 'ngx-avatars';
import { AccountHolderService } from 'src/app/services/account-holder.service';
import { SheepService } from 'src/app/services/sheep.service';

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, AvatarModule, TranslateModule],
  selector: 'app-group-leader-select',
  templateUrl: './group-leader-select.component.html',
  styleUrls: ['./group-leader-select.component.scss'],
})
export class GroupLeaderSelectComponent implements OnChanges {
  @Input() title = 'Search';
  @Input() multiple = false;
  @Output() selectedChanged: EventEmitter<any> = new EventEmitter();

  public data: any[] = [];
  fetching: boolean = false;
  isOpen=false;
  selected:any[];
  filtered: any[] = [];
  filterData: string|undefined;
  
  church = localStorage.getItem('userChurchId');

  constructor(
    public accountHolderService: AccountHolderService,
    public sheepService: SheepService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // this.loadData();
  }

  loadData(event: any = null) {
    if (!event) {
      this.fetching = true;
    }
    this.sheepService.getGroupLeaders(this.church, 0).subscribe((response) => {
      if(response.success){
        this.data = response.leaders;
      }
      this.fetching = false;
      if (event) {
        (event as RefresherCustomEvent).target.complete();
      }
    })
  }

  loadMore(event: any){
    this.sheepService.getGroupLeaders(this.church, this.data.length).subscribe((response) => {
      if(response.success){
        if(this.data.length == 0 ){
          this.data = response.leaders;
        } else {
          this.data.push(...response.leaders);
        }
      }

      (event as InfiniteScrollCustomEvent).target.complete();
    })
  }

  open(){
    this.isOpen = true;
    this.loadData();
  }

  cancel(){
    this.isOpen = false;
  }

  select(){
    const selected = this.data.filter((item) => item.selected);
    this.selected = selected;
    this.selectedChanged.emit(selected);
    this.isOpen = false;
  }
  
  selectedItem(item: any){
    this.selected = [item];

    if(!this.multiple){
      if(this.selected.length){
        this.selected[0].selected = false;
      }
      this.selectedChanged.emit(this.selected);
      this.isOpen = false;
      this.filtered = this.data;
    }
  }

}
