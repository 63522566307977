<ion-content class="ion-padding ion-text-center ion-justify-content-center">
  <div class="d-flex flex-div ion-padding ion-margin-top">
    <img src="/assets/page_not_found.svg" alt="">
  </div>
  <h2>Avance</h2>
  <p translate>page_currentlyprogress_pending</p>
  <br>
  <ion-button fill="outline" expand="block" (click)="goBack()" shape="round">
    <ion-icon slot="start" name="arrow-undo-outline"></ion-icon>
    {{'clickhere_goback' | translate}}
  </ion-button>
</ion-content>
