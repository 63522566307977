import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(
    private storage: Storage
  ) {
    this.init();
  }

  public isAuthenticated() { 
    return localStorage.getItem('token'); 
  }

  public getUser() { 
    return localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'); 
  }

  public clear(){ 
    return localStorage.clear(); 
  }

  async init(){
    const storage = await this.storage.create();
    this._storage = storage;
  }

  public set(key: string, value: any) {
    return this._storage?.set(key, value);
  }

  public get(key: string) {
    return this._storage?.get(key);
  }

  public persistCourseProgress(courseId: number, value: any) {
    return this._storage?.set('course_' + courseId, value);
  }

  public async getPersistedCourseProgress(courseId: number, key?: string) {
    let data = await this._storage?.get('course_' + courseId);
    if (data && key) {
      return data[key] || null;
    }
    return data;
  }

  public setCourseSessionNoteRead(courseId: number, sessionId: number, read: boolean = true) {
    return this._storage?.set('course_' + courseId + '_session_' + sessionId, read);
  }

  public async getCourseSessionNoteRead(courseId: number, sessionId: number) {
    return await this._storage?.get('course_' + courseId + '_session_' + sessionId);
  }
}
