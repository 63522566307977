<div *ngIf="selected; else placeholder">
  <span *ngFor="let item of selected; let last = last">
    {{ item.name }} {{ last? "": ", " }}
  </span>
</div>

<ng-template #placeholder><ion-icon color="medium" class="font-s-sm" name="caret-down-sharp"></ion-icon></ng-template>

<ion-modal [isOpen]="isOpen">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button color="dark" (click)="cancel()" translate>cancel</ion-button>
        </ion-buttons>
        <ion-title>{{title}}</ion-title>
        <ion-buttons slot="end" *ngIf="multiple==true">
          <ion-button (click)="select()" translate>select</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content >
      <ion-refresher slot="fixed" (ionRefresh)="loadData($event)" pullFactor="0.8" pullMin="60" pullMax="120">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <ion-spinner class="spinner-container" *ngIf="fetching"></ion-spinner>

      <ion-item *ngFor="let item of data"  (click)="selectedItem(item)">
        <ion-avatar>
          <ngx-avatars initialsSize="3" [name]="item.name" [src]="item.user_photo" size="38"></ngx-avatars>
        </ion-avatar>
        <ion-checkbox slot="start" [(ngModel)]="item.selected"></ion-checkbox>
        <ion-label class="pl-20">{{ item.name }}</ion-label>
      </ion-item>
      <div  *ngIf="data.length == 0">
        <ion-card class="center" translate>
          nogroup_leadersavailable
        </ion-card>
      </div>

      <ion-infinite-scroll threshold="25%" position="bottom" (ionInfinite)="loadMore($event)" [disabled]="!data.length">
        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="{{'loading_more'|translate}}...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ng-template>
  
</ion-modal>