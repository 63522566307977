import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollCustomEvent, IonicModule, RefresherCustomEvent, SearchbarCustomEvent } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AvatarModule, AvatarSource } from 'ngx-avatars';
import { FetchDataService } from 'src/app/services/fetch-data.service';

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, AvatarModule, TranslateModule],
  selector: 'app-searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss'],
})
export class SearchableSelectComponent implements OnChanges {
  @Input() title = 'Search';
  /** @deprecated */
  @Input() sameChurch: boolean = true;
  @Input() showPlaceholder: boolean = true;
  @Input() canUnselect: boolean = true;
  @Input() inclusive?: boolean;
  @Input() data: any[] = [];
  @Input() multiple = false;
  @Input() selected: any[] = [];
  @Input() itemTextField : string;
  @Input() placeholder : string = this.translate.instant('select_church_member');
  @Input('display-count') displayCount : boolean = false;
  @Output() selectedChanged: EventEmitter<any> = new EventEmitter();

  church: number = Number(localStorage.getItem('userChurchId'));

  isOpen=false;
  isSearching = false;
  searching = false;
  fetching: boolean = false;
  filtered: any[] = [];
  filterData: string|undefined;

  selectedDataCopy: any[] = [];
  churches: any[] = [];

  
  constructor(
    public fetchData: FetchDataService,
    private translate: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // this.filtered = this.data;
    this.church = Number(localStorage.getItem('userChurchId'));
    if (changes['selected']?.currentValue) {
      this.selected = changes['selected'].currentValue;
    }

    if (this.multiple) {
      this.placeholder = this.translate.instant('select_church_members');
    }

    this.churches = JSON.parse(localStorage.getItem("churches") || "[]");
  }

  loadData(refreshOrInfiniteEvent: any = null, isInfinite: boolean = false) {
    if(this.isSearching == true && this.filterData){
      this.searching = true;
      this.fetchData.searchFamilyMembers(
        this.filterData, 
        this.churches.length ? this.churches[0].church_id : this.church,
        isInfinite ? this.filtered.length : 0,
        this.churches.length > 1 ? this.churches[1].church_id : null,
        this.inclusive
      ).subscribe((respData: any) => {
        this.searching = false;
        if(respData.family_members) {
          if (isInfinite) {
            this.filtered.push(
              ...respData.family_members.map((item: any) => {
                  item.selected = this.isSelected(item);
                  return item;
                })
              );
          } else {
            this.filtered = respData.family_members.map((item: any) => {
              item.selected = this.isSelected(item);
              return item;
            });
          }
        }
        if (refreshOrInfiniteEvent) {
          (refreshOrInfiniteEvent as RefresherCustomEvent|InfiniteScrollCustomEvent).target.complete();
        }
      });
    }else{
      if (!refreshOrInfiniteEvent) {
        this.fetching = true;
      }
      this.fetchData.getFamilyMembers(
        this.churches.length ? this.churches[0].church_id : this.church,
        isInfinite ? this.filtered.length : 0,
        this.churches.length > 1 ? this.churches[1].church_id : null,
        this.inclusive
      ).subscribe((respData: any) => {
        if(respData.family_members) {
          if (isInfinite) {
            this.data.push(
              ...respData.family_members.map((item: any) => {
                item.selected = this.isSelected(item);
                return item;
              })
            );
          } else {
            this.data = respData.family_members.map((item: any) => {
              item.selected = this.isSelected(item);
              return item;
            });
          }
        }
        if (refreshOrInfiniteEvent) {
          (refreshOrInfiniteEvent as RefresherCustomEvent|InfiniteScrollCustomEvent).target.complete();
        }
        this.fetching = false;
      });
    }
  }

  open(){
    this.filtered = this.data = [];
    this.isOpen = true;
    this.loadData();
  }

  isSelected(user: any) {
    return this.selected.some(e => e.family_member_id === user.family_member_id || e.user_id == user.family_member_id);
  }

  cancel(){
    this.isOpen = false;
  }

  select(){
    let selected: any[] = [];
    if(this.isSearching == true && this.filtered.length){
      selected = this.filtered.filter((item) => item.selected);
    }else{
      selected = this.data.filter((item) => item.selected);
    }
    this.selected = selected;
    this.selectedChanged.emit(selected);
    this.isOpen = false;
  }

  itemSelected(item: any){
    if(!this.multiple){
      this.selected = [item];
      this.selectedChanged.emit(this.selected);
      this.isOpen = false;
      // this.selected = [];
      // this.data.map(item => (item.selected = false))
    }
  }

  filter(event: any) {
    this.isSearching = true;
    this.filterData = event.detail.value?.toLowerCase();
    if(this.filterData){
      this.isSearching = true;
      if(this.filterData.length >= 3) {
        this.searching = true;
        this.fetchData.searchFamilyMembers(
          this.filterData,
          this.churches.length ? this.churches[0].church_id : this.church,
          0,
          this.churches.length > 1 ? this.churches[1].church_id : null,
          this.inclusive
        ).subscribe((respData: any) => {
          this.searching = false;
          if(respData.family_members) {
            this.filtered = respData.family_members;
          }else{
            this.filtered = [];
          }
        });
      } else {
        this.filtered = [];
      }
    }else{
      this.isSearching = false;
      this.fetchData.getFamilyMembers(
          this.churches.length ? this.churches[0].church_id : this.church,
          0,
          this.churches.length > 1 ? this.churches[1].church_id : null,
          this.inclusive
        ).subscribe((respData: any) => {
        this.searching = false;
        if(respData.family_members) {
          this.data = respData.family_members;
        }
      });
    }
  }

  leaf = (obj: any) => this.itemTextField.split('.').reduce((value, el) => value[el], obj);

}
