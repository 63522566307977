<div *ngIf="selected; else placeholder">
  <span *ngFor="let item of selected; let last = last">
    {{ item.shepherd_name }} {{ last? "": ", " }}
  </span>
</div>

<ng-template #placeholder><ion-icon color="medium" class="font-s-sm" name="caret-down-sharp"></ion-icon></ng-template>

<ion-modal [isOpen]="isOpen">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button color="dark" (click)="cancel()" translate>cancel</ion-button>
        </ion-buttons>
        <ion-title>{{title}}</ion-title>
        <ion-buttons slot="end" *ngIf="multiple==true">
          <ion-button (click)="select()" translate>select</ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar (ionChange)="filter($event)"></ion-searchbar>
      </ion-toolbar>
    </ion-header>
    <ion-content >
      <div *ngIf="isSearching==true else notSearching">
        <ion-item *ngFor="let item of filtered"  (click)="selectedItem(item)">
          <ion-avatar>
            <ngx-avatars initialsSize="3" [name]="item.shepherd_name" [src]="item.photo" size="38"></ngx-avatars>
          </ion-avatar>
          <ion-checkbox slot="start" [(ngModel)]="item.selected"></ion-checkbox>
          <ion-label class="pl-20">{{ item.shepherd_name }}</ion-label>
        </ion-item>
        <div  *ngIf="filtered.length == 0 && isSearching">
          <ion-card class="center" *ngIf="searching == true">
            <ion-spinner ></ion-spinner>
          </ion-card>
          <ion-card class="center" *ngIf="searching == false" translate>
            noitems_matched_search
          </ion-card>
        </div>
      </div>

      <ng-template #notSearching>
        <ion-item *ngFor="let item of data"  (click)="selectedItem(item)">
          <ion-avatar>
            <ngx-avatars initialsSize="3" [name]="item.shepherd_name" [src]="item.photo" size="38"></ngx-avatars>
          </ion-avatar>
          <ion-checkbox slot="start" [(ngModel)]="item.selected"></ion-checkbox>
          <ion-label class="pl-20">{{ item.shepherd_name }}</ion-label>
        </ion-item>
        <div  *ngIf="data.length == 0">
          <ng-container *ngIf="fetching; else notFetching">
            <div class="ion-text-center">
              <ion-spinner ></ion-spinner>
            </div>
          </ng-container>
          <ng-template #notFetching>
            <ion-card class="center" translate>
              nousers_available
            </ion-card>
          </ng-template>
        </div>
      </ng-template>

      <ion-infinite-scroll threshold="25%" position="bottom" (ionInfinite)="loadData($event)">
        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="{{'loading_more'|translate}}...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ng-template>
  
</ion-modal>

