import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { APIENDPOINT } from '../helpers/constant/api-end-points';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SheepService {

  constructor(
    private baseService: BaseService,
    public toastController: ToastController
  ) { }

  getNewsPollOrPrayerRequestDetails(id: number, type: 'news'|'poll'|'prayer' = 'news') {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.NEWS.GETNEWSITEM + id + '/' + type).pipe(catchError(this.errorHandler));
  }

  getMarketingNews(offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.MARKETING.LIST + offset).pipe(catchError(this.errorHandler));
  }

  getMarketingNewsDetails(id: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.MARKETING.GET + id).pipe(catchError(this.errorHandler));
  }

  //sheep submit news for approval
  submitNews(newsItem: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.NEWS.SUBMITNEWS, newsItem).pipe(catchError(this.errorHandler));
  }

  //get sheep's linked churches
  getLinkedChurches(id: any){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.LINKEDCHURCHES + id).pipe(catchError(this.errorHandler));
  }

  //get sheep profile
  getSheepProfile(id: any){
    return this.baseService.get(environment.baseUrl +  APIENDPOINT.SHEEP.PROFILE.SHOW + id).pipe(catchError(this.errorHandler));
  }

  changePreferredLanguage(language: string) {
    return this.baseService.post(environment.baseUrl +  APIENDPOINT.SHEEP.CHANGELANGUAGE, {language}).pipe(catchError(this.errorHandler));
  }

  //get sheep profile
  updateSheepProfile(id: any, sheepData: any[]){
    return this.baseService.post(environment.baseUrl +  APIENDPOINT.SHEEP.PROFILE.EDIT + id, {...sheepData}).pipe(catchError(this.errorHandler));
  }

  //update relational status
  updateSheepRelationalStatus(id: any, body: any){
    return this.baseService.post(environment.baseUrl +  APIENDPOINT.SHEEP.PROFILE.EDITRELATIONALSTATUS.replace('{sheep}', id), body).pipe(catchError(this.errorHandler));
  }
  //update employment status
  updateSheepEmploymentStatus(id: any, body: any){
    return this.baseService.post(environment.baseUrl +  APIENDPOINT.SHEEP.PROFILE.EDITEMPLOYMENTSTATUS.replace('{sheep}', id), body).pipe(catchError(this.errorHandler));
  }

  //get list of new news and polls
  getNewNewsPolls(sheepId:any, churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.NEWS.LISTNEWNEWSPOLLS + sheepId + '/' + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  //get list of seen news and polls
  getSeenNewsPolls(sheepId:any, churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.NEWS.LISTSEENNEWSPOLLS + sheepId + '/' + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  //get list of seen news and polls
  getCityNews(offset: number = 0, requestBody: any|null = null){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.NEWS.CITYNEWS + offset, requestBody).pipe(catchError(this.errorHandler));
  }

  //move news or poll to seen
  moveToSeen(newsItem: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.NEWS.MOVENEWSPOLLSTOSEEN, newsItem).pipe(catchError(this.errorHandler));
  }

  //add comment to news
  addCommentToNews(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.NEWS.ADDNEWSCOMMENT, req).pipe(catchError(this.errorHandler));
  }

  //add comment to poll
  addCommentToPoll(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.NEWS.ADDPOLLCOMMENT, req).pipe(catchError(this.errorHandler));
  }

  //list news comments
  getNewsComments(newsId: any){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.NEWS.LISTNEWSCOMMENTS + newsId).pipe(catchError(this.errorHandler));
  }

  //list poll comments
  getPollComments(pollId: any){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.NEWS.LISTPOLLCOMMENTS + pollId).pipe(catchError(this.errorHandler));
  }

  //add news reaction
  addNewsReaction(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.NEWS.ADDNEWSREACTION, req).pipe(catchError(this.errorHandler));
  }

  //add poll reaction
  addPollReaction(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.NEWS.ADDPOLLREACTION, req).pipe(catchError(this.errorHandler));
  }

  //vote on polls
  addPollVote(req:any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.NEWS.ADDPOLLVOTE, req).pipe(catchError(this.errorHandler));
  }

  // add or edit a kid
  addEditKid(sheepId: number, req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.KIDS.ADD_EDIT + sheepId, req).pipe(catchError(this.errorHandler));
  }

  // list family requests
  listFamilyMemberRequested(sheepId: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.FAMILYMEMBERS.LIST_REQUESTS + sheepId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  // link or unlink a family
  linkUnlinkFamily(sheepId: number, familyId: number, req: {status: string|null, relationship: string|null} = {status: null, relationship: null}){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.FAMILYMEMBERS.LINK_DELINK + sheepId + '/' + familyId, req).pipe(catchError(this.errorHandler));
  }

  // accept or delink a family member request
  acceptDeclineFamilyRequest(sheepId: number, familyId: number, action: string){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.FAMILYMEMBERS.ACCEPT_DECLINE + sheepId + '/' + familyId, {action}).pipe(catchError(this.errorHandler));
  }

  // link a group
  linkChurch(sheepId: number, churchId: number, req: {reason: string, congregation: number}){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CHURCH.LINK + sheepId + '/' + churchId, req).pipe(catchError(this.errorHandler));
  }

  // delink a church
  delinkChurch(req: {sheep_id: number, church_id: number, reason_for_unlinking: string}){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CHURCH.DELINK, req).pipe(catchError(this.errorHandler));
  }

  //giving module

  //Link paypal account for user
  linkPaypalAccount(paypal_email: string){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.PAYPAL, {paypal_email}).pipe(catchError(this.errorHandler));
  }

  //list all funds and fundraisers in the church
  getFundsAndFundraisers(churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.GIVING.LISTFUNDSANDFUNDRAISERS + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  //make donations to fund or fundraiser
  makeDonation(body: {church_id: number, id: number, type: 'fund'|'fundraiser', amount: number, anonymous: boolean, recurring: boolean, recurring_option?: string}){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.GIVING.DONATE, body).pipe(catchError(this.errorHandler));
  }

  //send order checks for payments
  checkOrder(orderId: any, captureId: any){
    let requestBody = {
      order_id: orderId,
      captured_order_id: captureId
    }

    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.GIVING.CHECKORDER, requestBody).pipe(catchError(this.errorHandler));
  }

  getRecurrentPaymentsList(church: number, offset: number = 0, status: string = 'active'){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.GIVING.RECURRINGPAYMENTS + church + '/' + offset + '/' + status).pipe(catchError(this.errorHandler));
  }

  cancelRecurringPayment(church_id: number, id: number, type: 'fund'|'fundraiser'){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.GIVING.CANCELRECURRING, {church_id, type, id}).pipe(catchError(this.errorHandler));
  }

  //get transaction history
  getTransactionHistory(churchId: any, req: any, offset: number = 0){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.GIVING.TRANSACTIONHISTORY + churchId + '/' + offset, req).pipe(catchError(this.errorHandler));
  }

  //groups and teams

  createGroup(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.CREATE, req).pipe(catchError(this.errorHandler));
  }

  createTeam(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.CREATE, req).pipe(catchError(this.errorHandler));
  }

  listGroup(churchId: number, offset: number = 0, sheep?: number|string){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.LIST + churchId + '/' + offset + ((sheep &&= '/' + sheep) || '')).pipe(catchError(this.errorHandler));
  }

  searchGroup(churchId: number, name: string, offset: number = 0, sheep?: number){
    let req = {
      church_id: churchId,
      name,
      sheep
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.SEARCH + offset, req).pipe(catchError(this.errorHandler));
  }

  listTeam(churchId: number, offset: number = 0, sheep?: number|string){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.LIST + churchId + '/' + offset + ((sheep &&= '/' + sheep) || '')).pipe(catchError(this.errorHandler));
  }

  searchTeam(churchId: number, name: string, offset: number = 0, sheep?: number){
    let req = {
      church_id: churchId,
      name,
      sheep
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.SEARCH + offset, req).pipe(catchError(this.errorHandler));
  }

  leaveAGroup(group: number, church: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.LEAVE + group + '/' + church).pipe(catchError(this.errorHandler));
  }
  leaveATeam(team: number, church: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.LEAVE + team + '/' + church).pipe(catchError(this.errorHandler));
  }
  
  joinGroup(churchId: any, groupId: any){
    let req = {
      church_id: churchId,
      group_id: groupId
    }

    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.JOIN, req).pipe(catchError(this.errorHandler));
  }

  joinTeam(churchId: any, teamId: any){
    let req = {
      church_id: churchId,
      team_id: teamId
    }

    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.JOIN, req).pipe(catchError(this.errorHandler));
  }

  listUserGroups(churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.USERGROUPS + churchId + '/' + offset ).pipe(catchError(this.errorHandler));
  }

  listUserTeams(churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.USERTEAMS + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getGroupDetails(churchId: any, groupId: any){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.GROUPDETAILS + churchId + '/' + groupId).pipe(catchError(this.errorHandler));
  }

  getTeamDetails(churchId: any, teamId: any){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.TEAMDETAILS + churchId + '/' + teamId).pipe(catchError(this.errorHandler));
  }

  deleteGroup(churchId: any, groupId: any){
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.DELETE + groupId + '/' + churchId).pipe(catchError(this.errorHandler));
  }

  deleteTeam(churchId: any, teamId: any){
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.DELETE + teamId + '/' + churchId).pipe(catchError(this.errorHandler));
  }

  updateGroup(groupId: any, req: any){
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.UPDATE + groupId, req).pipe(catchError(this.errorHandler));
  }

  updateTeam(teamId: any, req: any){
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.UPDATE + teamId, req).pipe(catchError(this.errorHandler));
  }

  getGroupLeaders(churchId: any, offset: number = 0, groupId: any = null){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.LEADERS + churchId + '/' + offset + ((groupId &&= '/' + groupId) || '')).pipe(catchError(this.errorHandler));
  }

  getTeamLeaders(churchId: any, teamId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.LEADERS + churchId + '/' + teamId + '/'  + offset).pipe(catchError(this.errorHandler));
  }

  handoverGroup(churchId: any, groupId: any, adminId: any){
    let req = {
      church_id: churchId,
      group_id: groupId,
      new_admin_id: adminId
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.HANDOVER, req).pipe(catchError(this.errorHandler));
  }

  handoverTeam(churchId: any, teamId: any, adminId: any){
    let req = {
      church_id: churchId,
      team_id: teamId,
      new_admin_id: adminId
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.HANDOVER, req).pipe(catchError(this.errorHandler));
  }

  inviteToGroup(churchId: any, groupId: any, userId: any){
    let req = {
      user_id: userId,
      church_id: churchId,
      group_id: groupId
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.INVITE, req).pipe(catchError(this.errorHandler));
  }

  inviteToTeam(churchId: any, teamId: any, userId: any){
    let req = {
      user_id: userId,
      church_id: churchId,
      team_id: teamId
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.INVITE, req).pipe(catchError(this.errorHandler));
  }

  sendEventInvite(req: {church_id: number, event_id: number, invitees: number[]}){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.INVITE, req).pipe(catchError(this.errorHandler));
  }

  getGroupParticipants(groupId: any, churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.PARTICIPANTS.LIST + groupId + '/' + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getTeamParticipants(teamId: any, churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.PARTICIPANTS.LIST + teamId + '/' + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  deleteGroupParticipant(groupId: any, churchId: any, participant: any){
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.PARTICIPANTS.REMOVE + groupId + '/' + churchId + '/' + participant).pipe(catchError(this.errorHandler));
  }

  deleteTeamParticipant(teamId: any, churchId: any, participant: any){
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.PARTICIPANTS.REMOVE + teamId + '/' + churchId + '/' + participant).pipe(catchError(this.errorHandler));
  }

  addGroupParticipant(groupId: any, req:any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.PARTICIPANTS.ADD + groupId, req).pipe(catchError(this.errorHandler));
  }

  addTeamParticipant(teamId: any, req:any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.PARTICIPANTS.ADD + teamId, req).pipe(catchError(this.errorHandler));
  }

  groupMarkAttendance(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.ATTENDANCE.MARK, req).pipe(catchError(this.errorHandler));
  }

  teamMarkAttendance(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.ATTENDANCE.MARK, req).pipe(catchError(this.errorHandler));
  }

  groupAttendanceData(churchId: any, groupId: any, filter: any){
    let req = {
      church_id: churchId,
      group_id: groupId,
      filter_by: filter
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.ATTENDANCE.DATA, req).pipe(catchError(this.errorHandler));
  }

  teamAttendanceData(churchId: any, teamId: any, filter: any){
    let req = {
      church_id: churchId,
      team_id: teamId,
      filter_by: filter
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.ATTENDANCE.DATA, req).pipe(catchError(this.errorHandler));
  }

  userGroupAttendance(churchId: any){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.ATTENDANCE.USER + churchId).pipe(catchError(this.errorHandler));
  }

  userTeamAttendance(churchId: any){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.ATTENDANCE.USER + churchId).pipe(catchError(this.errorHandler));
  }

  sendGroupMessage(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.MESSAGES.SEND, req).pipe(catchError(this.errorHandler));
  }

  getGroupMessage(churchId: any, groupId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.MESSAGES.GET + churchId + '/' + groupId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  sendTeamMessage(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.MESSAGES.SEND, req).pipe(catchError(this.errorHandler));
  }

  getTeamMessage(churchId: any, teamId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.MESSAGES.GET + churchId + '/' + teamId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  receiptGroupMessage(messageId: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.MESSAGES.RECEIPT(messageId)).pipe(catchError(this.errorHandler));
  }

  receiptTeamMessage(messageId: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.MESSAGES.RECEIPT(messageId)).pipe(catchError(this.errorHandler));
  }

  readGroupMessage(messageId: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.MESSAGES.READ(messageId)).pipe(catchError(this.errorHandler));
  }

  readTeamMessage(messageId: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.MESSAGES.READ(messageId)).pipe(catchError(this.errorHandler));
  }

  groupStats(churchId: any){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.STATS + churchId).pipe(catchError(this.errorHandler));
  }

  teamStats(churchId: any){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.STATS + churchId).pipe(catchError(this.errorHandler));
  }

  getGroupUpcomingEvents(groupId: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.EVENTS.UPCOMING + groupId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getGroupUpcomingEventsCount(groupId: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.EVENTS.UPCOMING_COUNT + groupId ).pipe(catchError(this.errorHandler));
  }

  getGroupEventDetails(groupId: number, eventId: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.GROUP.EVENTS.DETAILS + groupId + '/' + eventId).pipe(catchError(this.errorHandler));
  }

  getTeamUpcomingEvents(teamId: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.EVENTS.UPCOMING + teamId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getTeamUpcomingEventsCount(teamId: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.EVENTS.UPCOMING_COUNT + teamId).pipe(catchError(this.errorHandler));
  }

  getTeamEventDetails(teamId: number, eventId: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.CONNECT.TEAM.EVENTS.DETAILS + teamId + '/' + eventId).pipe(catchError(this.errorHandler));
  }


  //events

  getUpcomingEvents(churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.GET.UPCOMING + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getRegisteredEvents(churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.GET.REGISTERED + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }
  
  exportAllRegisteredEvents(churchId: any){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.GET.EXPORT + churchId).pipe(catchError(this.errorHandler));
  }

  getEventPurchasedTickets(churchId: number, event: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.PURCHASED_TICKETS + churchId + '/' + event + '/' +offset).pipe(catchError(this.errorHandler));
  }

  public eventRegistration(church_id: number, event_id: number){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.REGISTER, {church_id, event_id}).pipe(catchError(this.errorHandler));
  }

  public buyEventTickets(church_id: number, event_id: number, tickets: { ticket_id: number, quantity: number }){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.BUYTICKETS, {church_id, event_id, tickets}).pipe(catchError(this.errorHandler));
  }

  public shareEventTicket(body: {ticket_serial_no: string, email: string, qr_code: string}){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.SHARETICKET, body).pipe(catchError(this.errorHandler));
  }

  unregisterFromEvent(event_id: number){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.UNREGISTER, {event_id}).pipe(catchError(this.errorHandler));
  }

  getCityEvents(churchId: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.GET.CITY + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  verifyEventPayment(order_id: string, captured_order_id: string){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.VERIFYPAYMENT, {order_id, captured_order_id}).pipe(catchError(this.errorHandler));
  }

  getEventsComments(event: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.COMMENT.LIST + event + '/' + offset).pipe(catchError(this.errorHandler));
  }

  addEventsComment(church_id: number, event_id: number, comment: string){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.COMMENT.ADD, {church_id, event_id, comment}).pipe(catchError(this.errorHandler));
  }

  removeEventComment(church_id: number, event_id: number, comment: number){
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.COMMENT.REMOVE + church_id + '/' + event_id + '/' + comment).pipe(catchError(this.errorHandler));
  }

  likeEvent(churchId: any, eventId: any, colour: string = 'red'){
    let body = {
      church_id: churchId,
      event_id: eventId,
      colour: colour
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.LIKE, body).pipe(catchError(this.errorHandler));
  }

  // rosters
  getSheepRostersList(churchId: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.ROSTERS.LIST + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getSheepRostersDetails(churchId: number, roster: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.ROSTERS.DETAILS + churchId + '/' + roster).pipe(catchError(this.errorHandler));
  }
  
  getRosterRequest(id: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.ROSTERS.REQUEST + id).pipe(catchError(this.errorHandler));
  }

  acceptRejectRequest(swapRequest: number, seeker: number, actionType: 'accept' | 'reject', isFrontend: boolean = true) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.ROSTERS.SWAPACCEPTREJECT + swapRequest + '/' + seeker + '/' + actionType + '/' + isFrontend).pipe(catchError(this.errorHandler));
  }

  getEventDetail(churhId: number, event: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.EVENT.GET.DETAIL + churhId + '/' + event).pipe(catchError(this.errorHandler));
  }

  /**
   * 
   * @param church the church id
   * @param user user id or 'me' or undefined
   * @param date the date to filter by month and year or undefined
   * @param offset the offset to skip data
   * @returns `Observable<any>`
   */
  getUsersUnavailabilitiesDates(church: number, user?: number|'me', date?: string|Date, offset: number = 0){
    let body = date ? {date, offset} : {offset};
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.ROSTERS.UNAVAILABLE_DATES + church + (user ? '/' + user : ''), body).pipe(catchError(this.errorHandler));
  }

  getSheepRosterParticipants(roster: number, schedule: number, date: string|null = null){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.ROSTERS.PARTICIPANTS + roster + '/' + schedule + (date ? '/' + date : '')).pipe(catchError(this.errorHandler));
  }

  changeAvailability(church_id: number, roster_id: number, schedule_id: number, date: {from: string, to: string}){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.ROSTERS.CHANGEAVAILABILITY, {church_id, roster_id, schedule_id, ...date}).pipe(catchError(this.errorHandler));
  }

  notAvailable(church_id: number, /** schedules: { roster_id: number, schedule_id: number}[], */range_date: {from_date: string, to_date: string} ){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.ROSTERS.NOTAVAILABILITY, {church_id, /** schedules, */...range_date}).pipe(catchError(this.errorHandler));
  }

  removeNotAvailable(id: number){
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEEP.ROSTERS.DELETENOTAVAILABILITY + id).pipe(catchError(this.errorHandler));
  }

  swapSchedule(church_id: number, roster_id: number, schedule_id: number, replacement_schedule_id: number){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.ROSTERS.SWAP, {church_id, roster_id, schedule_id, replacement_schedule_id}).pipe(catchError(this.errorHandler));
  }

  replaceSchedule(church_id: number, roster_id: number, schedule_id: number, replacement_schedule_id: number){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.ROSTERS.REPLACE, {church_id, roster_id, schedule_id, replacement_schedule_id}).pipe(catchError(this.errorHandler));
  }

  getRostersOnDuty(church: number, date: string, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.ROSTERS.ONDUTY + church + '/' + date + '/' +offset).pipe(catchError(this.errorHandler));
  }

  getFutureDateOnDuty(church: number, date: string){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.ROSTERS.FASTFORWARD + church + '/' + date ).pipe(catchError(this.errorHandler));
  }

  // Courses
  getCoursesInSheepStore(church: number, offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.LIST.INSTORE + church + '/' +offset).pipe(catchError(this.errorHandler));
  }

  getMyJourneyCoursesList(church: number, offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.LIST.MYJOURNEY + church + '/' +offset).pipe(catchError(this.errorHandler));
  }

  getCoachingStudentCoursesList(church: number, offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.LIST.MYSTUDENTS + church + '/' +offset).pipe(catchError(this.errorHandler));
  }

  getCoursesSession(church: number, course: number, session?: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.SESSION.STUDENT + church + '/' + course + (session ? '/' + session : '') ).pipe(catchError(this.errorHandler));
  }

  getCoursesSessionForCoach(church: number, course: number, session: number, student: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.SESSION.COACH  + church + '/' + course + '/' + session + '/' + student ).pipe(catchError(this.errorHandler));
  }

  addTestimony(church_id: number, course_id: number, testimony: string) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.TESTIMONY.ADD, {church_id, course_id, testimony}).pipe(catchError(this.errorHandler));
  }

  saveCourseProgress(church_id: number, course_id: number, body: {church_id?: number, session_id: number, course_id?: number, type: string, quizzes: { quiz_id: number, option_id: number}[]}) {
    body.church_id = church_id; body.course_id = course_id;
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.SAVE_PROGRESS, body).pipe(catchError(this.errorHandler));
  }

  getCourseRequests(church_id: number, offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.REQUEST.LIST  + church_id + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getCourseResults(course_id: number, church_id: number, offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.RESULTS  + course_id + '/' + church_id + '/' + offset).pipe(catchError(this.errorHandler));
  }

  sendCoachRequest(church_id: number, coach_id: number, course_id: number) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.REQUEST.SEND, {church_id, coach_id, course_id}).pipe(catchError(this.errorHandler));
  }

  approveCoachingRequest(church_id: number, coach_request_id: number) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.REQUEST.APPROVE, {church_id, coach_request_id}).pipe(catchError(this.errorHandler));
  }

  rejectCoachingRequest(church_id: number, coach_request_id: number) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.REQUEST.REJECT, {church_id, coach_request_id}).pipe(catchError(this.errorHandler));
  }

  enrollCourse(church_id: number, course_id: number) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.ENROLLMENT, {church_id, course_id}).pipe(catchError(this.errorHandler));
  }

  payCourseForFamilyMembers(church_id: number, course_id: number, links: number[]) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.PAYFORLINKS, {church_id, course_id, links}).pipe(catchError(this.errorHandler));
  }

  getFamilyLinksForCourse(course_id: number, church_id: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.LINKSLIST + course_id + '/' + church_id).pipe(catchError(this.errorHandler));
  }

  restartCourse(church_id: number, course_id: number) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.RESTART, {church_id, course_id}).pipe(catchError(this.errorHandler));
  }

  getCurrentCourseDetails(church_id: number, course_id: number, student: number, coach?: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.CURRENT + church_id + '/' + course_id + '/' + student + (coach ? '/' + coach : '')).pipe(catchError(this.errorHandler));
  }

  sendMessageInCourse(church_id: number, course_id: number, body: {church_id?: number, course_id?: number, receiver_id: number, is_coach: boolean, message: string, voice_note?: string, media?: {type: string, data: string}[]}) {
    body.church_id = church_id; body.course_id = course_id;

    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.MESSAGE.SEND, body).pipe(catchError(this.errorHandler));
  }

  getMessagesInCourse(church_id: number, course_id: number, offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.MESSAGE.LIST + church_id + '/' + course_id + '/' + offset).pipe(catchError(this.errorHandler));
  }

  markCoachSessionAsCompleted(church_id: number, course_id: number, session_id: number, user_id: number) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.MARKAS_COMPLETED, {church_id, course_id, session_id, user_id}).pipe(catchError(this.errorHandler));
  }

  deactivateAccount() {
    let userId = Number(localStorage.getItem('userId'));
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.DEACTIVATEACCOUNT, {userId}).pipe(catchError(this.errorHandler));
  }

  // SERMONS and PRAUYER REQUEST
  getSermonsList(offset: number = 0, filter: {church_id?: number, filter_by?: string, name?: string} = {}) {
    let churchId = Number(localStorage.getItem('userChurchId'));
    filter.church_id = churchId;
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.SERMONS.LIST + offset, filter).pipe(catchError(this.errorHandler));
  }

  shareSermonQRCode(data: {email: string, link: string, qrcode: string}, sermon: number) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.SERMONS.SHAREQRCODE + sermon, data).pipe(catchError(this.errorHandler));
  }
  
  getSermonsSeriesList(church: number, offset: number = 0, name: string = '') {
    if (name.length) {
      return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.SERMONS.SERIES + church + '/' + offset, {name}).pipe(catchError(this.errorHandler));
    }
    
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.SERMONS.SERIES + church + '/' + offset).pipe(catchError(this.errorHandler));
  }
  
  getSermonDetail(sermon: number, church: number = Number(localStorage.getItem('userChurchId'))) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.SERMONS.DETAIL + sermon + '/' + church).pipe(catchError(this.errorHandler));
  }
  
  markDiscussionAsSeen(church_id: number = Number(localStorage.getItem('userChurchId')), sermon_id: number, discussion_id: number) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.SERMONS.MARKDISCUSSION, {church_id, sermon_id, discussion_id}).pipe(catchError(this.errorHandler));
  }
  
  saveSermonProgress(church_id: number = Number(localStorage.getItem('userChurchId')), sermon_id: number, progress: number) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.SERMONS.PROGRESS, {church_id, sermon_id, progress}).pipe(catchError(this.errorHandler));
  }
  
  addSermonNote(church_id: number = Number(localStorage.getItem('userChurchId')), sermon_id: number, note: string) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.SERMONS.ADDNOTE, {church_id, sermon_id, note}).pipe(catchError(this.errorHandler));
  }
  
  removeSermonNote(church_id: number = Number(localStorage.getItem('userChurchId')), sermon_id: number, note: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEEP.SERMONS.REMOVENOTE + church_id + '/' + sermon_id + '/' + note).pipe(catchError(this.errorHandler));
  }
  
  getCommentsForPrayerRequest(prayer_request_id: number, offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.PRAYER_REQUEST.COMMENTS_REACTIONS(prayer_request_id, offset)).pipe(catchError(this.errorHandler));
  }

  addCommentOnPrayerRequest(church_id: number, prayer_request_id: number, comment: string) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.PRAYER_REQUEST.ADDCOMMENT, {church_id, prayer_request_id, comment}).pipe(catchError(this.errorHandler));
  }
  
  deleteCommentOnPrayerRequest(church: number, prayer: number, comment: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEEP.PRAYER_REQUEST.DELETECOMMENT + church + '/' + prayer + '/' + comment).pipe(catchError(this.errorHandler));
  }
  
  deleteCommentOnPrayerRequestAsShepherd(church: number, prayer: number, comment: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEEP.PRAYER_REQUEST.REMOVECOMMENT + church + '/' + prayer + '/' + comment).pipe(catchError(this.errorHandler));
  }
  
  reactOnPrayerRequest(church_id: number, prayer_request_id: number, reaction: string = 'primary') {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.PRAYER_REQUEST.REACT, {church_id, prayer_request_id, reaction}).pipe(catchError(this.errorHandler));
  }

  changeCongregation(church_id: number, congregation_id:number){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CHANGECONGREGATION + church_id + '/' + congregation_id,{}).pipe(catchError(this.errorHandler));
  }

  changePassword(body:any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.CHANGEPASSWORD,body).pipe(catchError(this.errorHandler));
  }

  getUserWalletInfo(){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.WALLET.USERWALLET_INFO).pipe(catchError(this.errorHandler));
  }
  
  //Method to handle error
  errorHandler(error:any) {
    return of(error);
  }

     //Error toast
  async errorToast(message: any) {
    const toast = await this.toastController.create({
        message: message,
        color: 'danger',
        buttons: ['OK']
      });
    toast.present();
  }

  async successToast(message: any) {
    const toast = await this.toastController.create({
        message: message,
        color: 'success',
        buttons: ['OK']
      });
    toast.present();
  }
}
