import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIENDPOINT } from '../constant/api-end-points';
import { StorageService } from './storage.service';
import { catchError } from 'rxjs/operators';
import { LoadingController, ToastController } from '@ionic/angular';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{

  //JSON object for exclude URLs for avoiding
  excludeURLs: any = {
    login: environment.baseUrl + APIENDPOINT.AUTH.LOGIN.VALIDATEUSER
  };

  constructor(private router: Router, public localStorage: StorageService, public toastController: ToastController, public loading: LoadingController) {  }

  //Interceptor method to set bearer token
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let usedRequest: HttpRequest<any> = request;
    if (!request.url.includes(this.excludeURLs.login) && !request.url.includes('google')) {
      usedRequest = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + this.localStorage.isAuthenticated()) });
    }else{
      if (!request.url.includes('google')) {
        let signature = this.makeSignature(this.excludeURLs.login);
        usedRequest = request.clone({ headers: request.headers.set('Authorization', signature) });
      }
    }

    if (!request.url.includes('google')) {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
  
      usedRequest = usedRequest.clone({ params: usedRequest.params.append('timezone', timezone) });
    }

    return next.handle(usedRequest).pipe(
      catchError((error) => {
        let handled: boolean = false;
        if (error instanceof HttpErrorResponse) {
          this.loading.dismiss().finally(() => null);
          // this.errorToast(error.status == 0 ? error.statusText : error.message);
          switch (error.status) {
            case 401:
              if (!this.router.url.includes('auth/login')) {
                this.localStorage.clear();
                window.location.reload();
                this.router.navigate(["auth/login"]);
              }
              handled = true;
              break;
          }
        }
        if (handled) {
          return of(error);
        } else {
          return throwError(error?.error ? error.error : error);
        }
      })
    );
  }

  public makeSignature(url: string): string { 
    let signature = CryptoJS.HmacSHA256(url, environment.SUPASHEEP_APP_SECRET);
    return 'Signature keyId="'.concat(environment.SUPASHEEP_APP_KEY, '",algorithm="hmac-sha256",signature="'+ signature +'"');
  }

  //Error toast
  async errorToast(message: any) {
    const toast = await this.toastController.create({
        message: message,
        color: 'danger',
        buttons: ['OK']
      });
    toast.present();
  }
}