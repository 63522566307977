import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../auth/storage.service';

@Injectable({
  providedIn: 'root'
})
export class PageRestrictGuard implements CanActivateChild {

  constructor(private router: Router, public localStorage: StorageService){ }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    if(this.localStorage.isAuthenticated()){
      const connectedUserRole = localStorage.getItem('role') as string;
      let url = state.url;
      url = url.startsWith('/') ? url.slice(1, url.length) : url;

      // For super admin, the connectedUserRole can not have multiple roles
      if(url.startsWith('super-admin') && (connectedUserRole != 'super_admin') || !url.startsWith('super-admin') && (connectedUserRole == 'super_admin')) {
        this.router.navigateByUrl("/not-authorized");
        return false;
      }
      
      // For Account Holder, the connectedUserRole can not have multiple roles
      if (url.startsWith('zone/account-holder') && (connectedUserRole != 'account_holder')) {
        this.router.navigateByUrl("/not-authorized");
        return false;
      }
      // in here, sheep have access to shepherd zone but not the data
      else if(url.startsWith('zone/shepherd') && (!connectedUserRole.includes('shepherd') && !/shepherd|account_holder|administrator|sheep/ig.test(connectedUserRole))) {
        this.router.navigateByUrl("/not-authorized");
        return false;
      } 
      // User that doesn't have a role included in Roles cannot access sheep zone
      else if (url.startsWith('zone/sheep') && !/shepherd|account_holder|administrator|sheep/ig.test(connectedUserRole)) {
        this.router.navigateByUrl("/not-authorized");
        return false;
      }
      // As all run well, we allow the user to continue to his route
      else {
        return true;
      }
    } else {
      let currentUrlPath = state.url;
      let url = "/auth/login" + (!currentUrlPath.includes('auth') && !currentUrlPath.includes('registration') ? '?redirectTo=' + currentUrlPath : '');

      this.router.navigateByUrl(url);
      return false;
    }
  }
  
}
