import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, catchError, map, of, noop } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIENDPOINT } from '../helpers/constant/api-end-points';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { BaseService } from './base.service';
import { LanguageType } from '../helpers/constant/supported-language';
import { registerLocaleData } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  public currentLang: string;
  public langTranslations = new Map;
  public langs: BehaviorSubject<{name: string, code: string}[]> = new BehaviorSubject([{name: 'English', code: 'en'}]);
  
  public systemLangs: BehaviorSubject<{
    name: string,
    code: string,
    default: boolean|any,
    published: boolean|any,
    created_at: string,
    updated_at: string,
    translations: any
  }[]> = new BehaviorSubject([{
    name: 'English',
    code: 'en',
    default: true,
    published: true,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    translations: {}
  }]);
  public loader: HTMLIonLoadingElement|undefined;

  constructor(
    public translate: TranslateService,
    private httpClient: HttpClient,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private toastController: ToastController,
    private baseService: BaseService
  ) {
    let currentLang = localStorage.getItem('currentLang') as string || translate.getDefaultLang();
    this.loadAngularLocales(currentLang)
  }

  getLangs() {
    return this.langs.asObservable();
  }

  getSystemLangs() {
    return this.systemLangs.asObservable();
  }

  async loadAngularLocales(langCode: string) {
    const locale = await import(
      `../../assets/i18n/ng-locales/${langCode.replace('_', '-')}.mjs`
    ).catch(noop);
    registerLocaleData(locale.default, langCode);
    this.translate.use(langCode);
    this.currentLang = this.translate.currentLang;
    localStorage.setItem('currentLang', this.currentLang);
  }

  public async changeLang(lang: string) {
    if (this.langTranslations.has(lang) && this.langTranslations.get(lang)) {
      this.translate.setTranslation(lang, this.langTranslations.get(lang));
      await this.loadAngularLocales(lang);

    } else if (lang !== this.currentLang) {
      await this.showLoader();

      this.translate.getTranslation(lang).subscribe({
        next: async (languageTranslations) => {
          this.showLoader(false)
          this.langTranslations.set(lang, languageTranslations);
          this.translate.setTranslation(lang, languageTranslations);
          await this.loadAngularLocales(lang);
        },
        error: (error) => {
          this.showLoader(false)
          this.showAlert();
        }
      })
    }
  }

  public langFileExists(langCode: string): Observable<boolean> {
    let url = environment.baseUrl + APIENDPOINT.LANGUAGE.LOAD + langCode;
    return this.httpClient.get(url).pipe(map(() => true), catchError(() => of(false)));
  }

  async loadLanguagesFromServer() {
    await this.showLoader();
    this.httpClient.get(environment.baseUrl + APIENDPOINT.LANGUAGE.LOADALL).pipe(catchError((error) => of(error))).subscribe(
      (res: {
        success: boolean,
        languages: {
          name: string,
          code: string,
          default: boolean,
          published: boolean,
          created_at: string,
          updated_at: string,
          translations: any
        }[]}) => {
          this.showLoader(false)
          if (res.success) {
            let langs = res.languages.map(
              (language) => {
                const {name, code} = language;
                return {name, code};
              }
            );
            this.langs.next(langs);
          }
      }
    );
  }

  async loadLanguagesFromServerForAdmin() {
    await this.showLoader();
    this.httpClient.get(environment.baseUrl + APIENDPOINT.LANGUAGE.GETLIST).pipe(catchError((error) => of(error))).subscribe(
      (res: {
        success: boolean,
        languages: {
          name: string,
          code: string,
          default: boolean,
          published: boolean,
          created_at: string,
          updated_at: string,
          translations: any
        }[]}) => {
          this.showLoader(false)
          if (res.success) {
            this.systemLangs.next(res.languages);
          }
      }
    );
  }

  loadALanguageFromServerForAdmin(code: string) {
    this.showLoader(true, "loading translations the selected langauge...");
    this.httpClient.get(environment.baseUrl + APIENDPOINT.LANGUAGE.GETLIST + '/' + code).pipe(catchError((error) => of(error))).subscribe(
      (res: {
        success: boolean,
        language: {
          name: string,
          code: string,
          default: boolean,
          published: boolean,
          created_at: string,
          updated_at: string,
          translations: any
        }}) => {
          this.showLoader(false)
          if (res.success) {
            let systemLangs = this.systemLangs.value;
            let index = systemLangs.findIndex((lang) => lang.code == code);
            if (index > -1) {
              systemLangs.splice(index, 1, res.language);
            } else {
              systemLangs.push(res.language);
            }
            this.systemLangs.next(systemLangs);
          }
      }
    );
  }

  addLanguage(lang: LanguageType) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.LANGUAGE.NEW , lang).pipe(catchError((error) => of(error)));
  }

  saveTranslations(code: string, translations: any) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.LANGUAGE.SAVE + code, {translations}).pipe(catchError((error) => of(error)));
  }

  publishLanguage(code: string) {
    return this.baseService.put(environment.baseUrl + APIENDPOINT.LANGUAGE.PUBLISH + code, {}).pipe(catchError((error) => of(error)))
  }

  unpublishLanguage(code: string) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.LANGUAGE.UNPUBLISH + code).pipe(catchError((error) => of(error)))
  }

  public async showLoader(present: boolean = true, message: string = 'Loading translations...') {
    const topLoader = await this.loadingController.getTop();
    if (topLoader) {
      await topLoader.dismiss();
    }
    if (present === true) {
      if (this.loader) {
        await this.loader.dismiss();
      }
      this.loader = await this.loadingController.create({
        message,
        backdropDismiss: false
      });
      await this.loader.present();
    } else if(this.loader) {
      await this.loader.dismiss();
      this.loader = undefined;
    }
  }

  public async toastMessage(message: string, color: string = 'medium') {
    let toast = await this.toastController.create({
      message,
      color,
      buttons: ['Ok']
    });
    await toast.present();
  }

  public async showAlert(header: string = "Error Warning", message: string = 'Translations for the selected language not found or not yet published.') {
    const understoodText = this.translate.instant('understood');
    let alerter = await this.alertController.create({
      header,
      message,
      backdropDismiss: false,
      buttons: [understoodText]
    });
    await alerter.present();
  }

}
