// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  /*
    Be careful with the baseUrl variable. As apart from being used in the app modules' files,
    this variable is also being used in the firebase-messaging-sw.js file which is a file
    that is a part of the notifications feature's configurations.
  */

  /* *** STAGING PLATFOTM *** */
  hostname: 'https://supasheep-staging.globaledge-software.com/',
  baseUrl: 'https://supasheep-api-staging.globaledge-software.com/api/',
  webSocketHost: 'supasheep-api-staging.globaledge-software.com',
  SUPASHEEP_APP_KEY:  'eyJhbGciOiJIUzI1NiJ9.ew0KICAibmFtZSI6ICJTdXBhIFNoZWVwIEFwcCIsDQogICJjb2RlIjogInN1cGFfc2hlZXBfYXBwIiwNCiAgImFjY2VzcyI6ICJrZXkiDQp9.rQ1ZO6PqTIfVq8nvBdvnvwvYY0HVBfkFN0A_GQBaPKk',
  SUPASHEEP_APP_SECRET: 'eyJhbGciOiJIUzI1NiJ9.ew0KICAibmFtZSI6ICJTdXBhIFNoZWVwIEFwcCIsDQogICJjb2RlIjogInN1cGFfc2hlZXBfYXBwIiwNCiAgImFjY2VzcyI6ICJzZWNyZXQiDQp9.cbknjgjEeRwQn1rg-zIz_LQjqw30Rg4kmzRGrU_jAK4',

  // /* *** LOCAL PLATFOTM *** */
  // hostname: 'http://localhost:8100/',
  // baseUrl: 'http://supasheep.local/api/',
  // webSocketHost: '127.0.0.1',
  // SUPASHEEP_APP_KEY: 'eyJhbGciOiJIUzI1NiJ9.ew0KICAibmFtZSI6ICJTdXBhIFNoZWVwIEFwcCIsDQogICJjb2RlIjogInN1cGFfc2hlZXBfYXBwIiwNCiAgImFjY2VzcyI6ICJrZSINCn0.y9lGwFs_F3XgixkhzUVJeuFibReSgT33nbSIjmTwJ9s',
  // SUPASHEEP_APP_SECRET: 'eyJhbGciOiJIUzI1NiJ9.ew0KICAibmFtZSI6ICJTdXBhIFNoZWVwIEFwcCIsDQogICJjb2RlIjogInN1cGFfc2hlZXBfYXAiLA0KICAiYWNjZXNzIjogInNlY3JldCINCn0.TTazAAeNZlDkYmSEcdSpsY6BkA2S_4OcnRe0dku7-Y4',

  firebase: {
    apiKey: "AIzaSyCbPPgf0k2q7tRDq-pwQyoR-tz3_ax1Pg8",
    authDomain: "supa-sheep.firebaseapp.com",
    projectId: "supa-sheep",
    storageBucket: "supa-sheep.appspot.com",
    messagingSenderId: "564399752165",
    appId: "1:564399752165:web:eeef66e236ddc4b016650c",
    measurementId: "G-XEPK38808P",
    vapidKey: 'BDuVd-Rck_iBM7_Bqc11kucBG6ch_6hsemhnibdHCzzHNwMt_fQEz0NzrB5O6BiUohaWEltGr6eZVzvaUloGmv8'
  },

  PAYPAL_CLIENT_ID: 'AT6jbt254N-UsmfXn3Nnwq5TT-o1ySka1cC4yFabvJSQaQ93lbH21t0T5nvgX-ji7pacTU2wUk8kHotN', //AZAyAXfn51I5Uk_Q0I4v6FKwwBVDHM1eyu9dF9ych6_Yca1xU_UCOWToB8oSR1syBlaCydeUUAlCITi1
  translateAPIKey: 'AIzaSyCdOg3vcENpwZlnlSP8RpX-_b3ymHDZrIc',
  colors: {
    accountHolder: {
      dark: '#283583',
      light: '#283583'
    },
    admin: {
      dark: '#C2A392',
      light: '#C2A392'
    },
    sheep: {
      dark: '#008EA3',
      light: '#008EA3'
    },
    shepherd: {
      dark: '#3D82C6',
      light: '#3D82C6'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
