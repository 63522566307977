import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss'],
})
export class NotAuthorizedComponent implements OnInit {

  constructor() { }

  connectedRole: string = localStorage.getItem('role') as string;
  backUrl: string = '/sheep/news';

  ngOnInit() {
    if(this.connectedRole == 'account_holder') {
      this.backUrl = '/account-holder/profile';
    } else if (this.connectedRole.includes('shepherd')) {
      this.backUrl = '/shepherd/sheep';
    } else if (this.connectedRole == 'super_admin') {
      this.backUrl = '/super-admin/dashboard';
    }
  }

}
