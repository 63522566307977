import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found.component';
import { PageRestrictGuard } from './helpers/guard/page-restrict.guard';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';

const routes: Routes = [
  { path: '',  redirectTo: 'zone', pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthPageModule) },
  {
    path: 'checkpoint',
    loadChildren: () => import('./pages/guest/guest.module').then( m => m.GuestPageModule)
  },
  {
    path: 'zone',
    loadChildren: () => import('./pages/portal.module').then( m => m.PortalPageModule),
    canActivateChild: [PageRestrictGuard]
  },
  { 
    path: 'super-admin',
    loadChildren: () => import('./pages/super-admin/super-admin.module').then( m => m.SuperAdminPageModule),
    canActivateChild: [PageRestrictGuard] 
  },
  { path: 'sheep', redirectTo: 'zone/sheep' },
  { path: 'shepherd', redirectTo: 'zone/shepherd' },
  { path: 'account-holder', redirectTo: 'zone/account-holder' },
  { path: 'not-authorized', component: NotAuthorizedComponent },
  { path: 'privacy-policy', loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule) },
  { path: 'TermsAndConditions', loadChildren: () => import('./pages/TermsAndConditions/TermsAndConditions.module').then( m => m.TermsAndConditionsPageModule) },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then( m => m.SupportPageModule)
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
